<template>
  <v-card flat class="pt-4 pb-0">
    <v-card-text>
      <v-btn
        color="blue"
        class="d-block mx-auto mt-5"
        outlined
        @click="showDialog = true"
        >Đổi mật khẩu</v-btn
      >
      <v-dialog v-model="showDialog" max-width="350px">
        <v-card :loading="loading">
          <v-card-title>
            <span class="headline">Đổi mật khẩu</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="form.oldPassword"
              :error-messages="oldPasswordErrors"
              @input="$v.form.oldPassword.$touch()"
              @blur="$v.form.oldPassword.$touch()"
              label="Mật khẩu cũ"
              type="password"
            ></v-text-field>
            <v-text-field
              v-model="form.password"
              :error-messages="passwordErrors"
              @input="$v.form.password.$touch()"
              @blur="$v.form.password.$touch()"
              label="Mật khẩu mới"
              type="password"
            ></v-text-field>
            <v-text-field
              label="Nhập lại password"
              type="password"
              v-model="form.passwordConfirmation"
              :error-messages="passwordConfirmationErrors"
              @input="$v.form.passwordConfirmation.$touch()"
              @blur="$v.form.passwordConfirmation.$touch()"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="d-block mx-auto"
              color="blue darken-1"
              text
              @click="closeDialog"
              >Huỷ</v-btn
            >
            <v-btn
              class="d-block mx-auto"
              color="blue darken-1"
              text
              @click="changePassword"
              >Xác nhận</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>
<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { changePassword } from "@/api/user";
export default {
  name: "edit-profile-form",
  props: {
    dataBackgroundColor: {
      type: String,
      default: "",
    },
  },
  validations() {
    return {
      form: {
        oldPassword: {
          required,
        },
        password: {
          required,
          minLength: minLength(6),
        },
        passwordConfirmation: {
          required,
          sameAsPassword: sameAs("password"),
        },
      },
    };
  },
  data() {
    return {
      loading: false,
      showDialog: false,
      form: {
        oldPassword: "",
        password: "",
        passwordConfirmation: "",
      },
      user: {
        name: "",
        position: "",
        email: "",
      },
    };
  },
  computed: {
    oldPasswordErrors() {
      const errors = [];
      if (!this.$v.form.oldPassword.$dirty) return errors;
      !this.$v.form.oldPassword.required && errors.push("Hãy nhập mật khẩu cũ");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required && errors.push("Hãy nhập mật khẩu");
      !this.$v.form.password.minLength &&
        errors.push("Mật khẩu tối thiểu 6 kí tự");
      return errors;
    },
    passwordConfirmationErrors() {
      const errors = [];
      if (!this.$v.form.passwordConfirmation.$dirty) return errors;
      !this.$v.form.passwordConfirmation.required &&
        errors.push("Hãy xác nhận lại mật khẩu");
      !this.$v.form.passwordConfirmation.sameAsPassword &&
        errors.push("Mật khẩu không trùng khớp");
      return errors;
    },
  },
  methods: {
    closeDialog() {
      this.showDialog = false;
      for (const field in this.form) this.form[field] = "";
      this.$v.form.$reset();
    },
    async changePassword() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        try {
          this.loading = true;
          await changePassword(this.form);
          this.loading = false;
          this.closeDialog();
          this.$snackbar("Cập nhật thành công", "success");
        } catch (error) {
          this.loading = false;
        }
      }
    },
  },
  created() {
    for (const key in this.user) {
      this.user[key] = this.$store.getters[key];
    }
    this.user.position = "Admin";
  },
};
</script>
<style></style>
